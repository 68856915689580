<template>
  <div>
    <div class="row-box">

      <div class="block">
        <el-date-picker v-model="time" type="date" placeholder="选择日期" value-format="yyyy-MM-dd" :clearable="false" @change="onload"></el-date-picker>
      </div>
      <el-row class="box-row">
        <el-col :span="3">
          <el-card class="box-card">
            <div class="el-icon-menu icon"></div>
            <div class="note">总览</div>
          </el-card>
        </el-col>
        <el-col :span="21">
          <el-card class="box-card">
            <el-row class="box-card-row">
              <el-col :span="5">
                <div class="num">{{objNum.importIntegral | toThousands}}</div>
                <div class="note">新增金额
                </div>
              </el-col>
              <el-col :span="5">
                <div class="num">{{objNum.exportIntegral | toThousands}}</div>
                <div class="note">导出金额
                </div>
              </el-col>
              <el-col :span="5">
                <div class="num">{{objNum.grantIntegral | toThousands}}</div>
                <div class="note">下发成功金额
                </div>
              </el-col>
              <el-col :span="5">
                <div class="num">{{objNum.returnSumIntegral | toThousands}}</div>
                <div class="note">退回金额
                </div>
              </el-col>
              <el-col :span="4">
                <div class="num">{{sumVouchersPrice | toThousands}}</div>
                <div class="note">异常金额
                </div>
              </el-col>
            </el-row>
          </el-card>
        </el-col>
      </el-row>
    </div>

    <el-table stripe :data="list" style="box-shadow: 0 1px 4px 0 rgba(0,0,0,0.1);overflow:auto" height="calc(100vh - 330px)">
      <el-table-column prop="organizationName" align="center" label="机构"></el-table-column>
      <el-table-column align="center" label="新增金额">
        <template slot-scope="scope">
          {{scope.row.importIntegral | toThousands}}
        </template>
      </el-table-column>
      <el-table-column align="center" label="导出金额">
        <template slot-scope="scope">
          {{scope.row.exportIntegral | toThousands}}
        </template>
      </el-table-column>
      <el-table-column align="center" label="下发成功金额">
        <template slot-scope="scope">
          {{scope.row.grantIntegral | toThousands}}
        </template>
      </el-table-column>
      <el-table-column align="center" label="退回金额">
        <template slot-scope="scope">
          {{scope.row.returnSumIntegral | toThousands}}
        </template>
      </el-table-column>
      <el-table-column align="center" label="异常金额">
        <template slot-scope="scope">
          {{scope.row.sumVouchersPrice | toThousands}}
        </template>
      </el-table-column>
    </el-table>
    <el-pagination layout="total,prev, pager, next,sizes" @size-change="handleSizeChange" @current-change="handleCurrentChange" background :page-size="pageSize" :current-page="pageNum" :total="total">
    </el-pagination>
  </div>
</template>
<script>
import { NumberSub } from '@/utils/utils'
import { countOrgIntegralByDate, countIntegralTotalByDate } from '@/api/clearing'
import moment from 'moment'
export default {
  name: 'finance',
  data() {
    return {
      list: [],
      total: 0,
      objNum: {},
      pageNum: 1,
      pageSize: 10,
      time: moment().format('YYYY-MM-DD')
    }
  },
  created() {
    this.onload()
  },
  filters: {
    toThousands(num = 0) {
      let c = (num.toString().indexOf('.') !== -1) ? num.toLocaleString() : num.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
      return c
    }
  },
  computed: {
    sumVouchersPrice() {
      return NumberSub(NumberSub(this.objNum.exportIntegral, this.objNum.grantIntegral), this.objNum.returnSumIntegral)
    }
  },
  methods: {
    onload() {
      countIntegralTotalByDate({ date: this.time }).then(res => {
        this.objNum = res.data || {}
      })
      this.handleCurrentChange(1)
    },
    getList() {
      countOrgIntegralByDate({ pageNum: this.pageNum, pageSize: this.pageSize, date: this.time }).then(res => {
        this.list = res.data.records || []
        this.list.forEach(item => {
          item.sumVouchersPrice = NumberSub(NumberSub(item.exportIntegral, item.grantIntegral), item.returnSumIntegral)
        });
        console.log(this.list)
        this.total = res.data.total
      })
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.getList()
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.getList()
    }
  }
}
</script>

<style scoped lang="scss">
.block {
  text-align: center;
}
.el-icon-question {
  font-size: 15px;
}
.row-box {
  width: 100%;
  overflow-x: scroll;
}
.box-row {
  width: 1080px;
  height: 100px;
  margin: 20px auto;
  .box-card {
    text-align: center;
    margin-left: 30px;
    height: 100px;
    .box-card-row {
      box-sizing: border-box;
    }
    .icon {
      color: #0099ff;
      font-size: 40px;
    }
    .note {
      font-size: 13px;
      margin-top: 10px;
    }
    .num {
      margin-top: 5px;
      font-size: 22px;
    }
  }
}
</style>